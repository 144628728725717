import React, {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
    Box,
    Button,
    Container,
    FormControl, Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import UserService from "../services/user.service";
import {ArrowBack, ArrowRight, OpenInNew} from "@mui/icons-material";

const User = () => {
    let navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    const { id } = useParams()
    const theme = useTheme()

    const [iscritto, setIscritto] = useState([])
    const [iscrizione, setIscrizione] = useState([])
    const [tutore, setTutore] = useState([])
    const [documenti, setDocumenti] = useState([])

    useEffect(() => {
        fetchData(id).then()
    }, [id])

    async function fetchData(id) {
        const response = await UserService.getUser(id)
        console.log(response.data)
        const iscrittoData = []
        for (const key in response.data.iscritto) {
            iscrittoData.push({
                id: key, title: key, value: response.data.iscritto[key]
            })
        }
        iscrittoData.sort(function(a, b) {
            return a.id < b.id ? -1 : 1;
        });
        setIscritto(iscrittoData);
        const iscrizioneData = []
        for (const key in response.data.iscrizione) {
            iscrizioneData.push({
                id: key, title: key, value: response.data.iscrizione[key]
            })
        }
        iscrizioneData.sort(function(a, b) {
            return a.id < b.id ? -1 : 1;
        });
        setIscrizione(iscrizioneData);
        const tutoreData = []
        for (const key in response.data.tutore) {
            tutoreData.push({
                id: key, title: key, value: response.data.tutore[key]
            })
        }
        tutoreData.sort(function(a, b) {
            return a.id < b.id ? -1 : 1;
        });
        setTutore(tutoreData);

        const documentiData = [];
        if (response.data.privacy) {
            documentiData['privacy'] = response.data.privacyUrl
        }
        if (response.data.fototessera) {
            documentiData['fototessera'] = response.data.fototesseraUrl
        }
        if (response.data.certificato) {
            documentiData['certificato'] = response.data.certificatoUrl
        }
        console.log(documentiData)
        setDocumenti(documentiData)
        setLoading(false)
    }

    return (
        <main>
            <Box>
                <Container maxWidth={false}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{width:'100%', maxWidth:'180px'}}>
                            { /*<img src={logo} width={'100%'} style={{paddingRight:'5rem', margin:'1rem 0'}}/> */ }
                        </div>
                    </div>
                </Container>
            </Box>
            <Container sx={{padding: '2rem'}}>
                <Button LinkComponent={Link} to={"/"} variant="outlined" sx={{marginBottom: '2rem'}} startIcon={<ArrowBack />}>Back</Button>
                <Typography variant={"h4"} sx={{textAlign: 'left'}}>{user?.name} {user?.surname}</Typography>
                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '1rem 0'}}>Iscritto</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {iscritto.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} disabled={false} required={true} id="name-input" value={g.value}/>
                        </FormControl>
                    )}
                </Box>
                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '1rem 0'}}>Iscrizione</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {iscrizione.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} disabled={false} required={true} id="name-input" value={g.value}/>
                        </FormControl>
                    )}
                </Box>
                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '1rem 0'}}>Tutore</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {tutore.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} disabled={false} required={true} id="name-input" value={g.value}/>
                        </FormControl>
                    )}
                </Box>
                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '1rem 0'}}>Documenti</Typography>
                <Grid container spacing={2} alignItems={'center'}>
                    {(!documenti['privacy'] || !documenti['fototessera'] || !documenti['certificato'])
                        && <>
                            <Grid item xs={6}><Typography>Nessun documento ricevuto.</Typography></Grid>
                            <Grid item xs={6}><Button variant={"outlined"}
                                                      endIcon={<OpenInNew/>}
                                                      onClick={() => window.open(`https://iscrizioni.atleticacernusco.it/upload/${id}`, '_blank')}>
                                Vai alla pagina di caricamento documenti
                            </Button></Grid>
                        </>
                    }
                    {'privacy' in documenti ? <Grid item><Button variant={"contained"} href={documenti['privacy']}>Privacy</Button></Grid> : <></>}
                    {'fototessera' in documenti ? <Grid item><Button variant={"contained"} href={documenti['fototessera']}>Fototessera</Button></Grid> : <></>}
                    {'certificato' in documenti ? <Grid item><Button variant={"contained"} href={documenti['certificato']}>Certificato</Button></Grid> : <></>}
                </Grid>
            </Container>
        </main>
    )
}

export default User
