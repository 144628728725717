import React, {useEffect, useMemo, useState} from "react";
import {Box, Container, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import UserService from "../services/user.service";
import {useNavigate} from "react-router-dom";
import {capitalizeFirst} from "../utils/utils";
import {Check, Close} from "@mui/icons-material";
import _ from 'lodash'
import {categories, subscriptionTypes} from "../config";

const Home = (factory, deps) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [categoryFilter, setCategoryFilter] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const csvOptions = { allColumns: true, utf8WithBom: true }

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        certificato: false,
        fototessera: false,
        privacy: false,
        "iscritto.dataDiNascita": true,
        "iscritto.comuneRedisenza": false,
        "iscritto.documento": false,
        "iscritto.numeroDocumento": false,
        "iscritto.scadenzaDocumento": false,
        "iscritto.nazionalita": false,
        "iscritto.via": false,
        "iscritto.numero": false,
        "iscritto.cap": false,
        "iscritto.citta": false,
        "iscrizione.categoria": true,
        "iscrizione.tipologia": true,
        "iscrizione.divisa": true,
        "iscrizione.zaino": true,
        "tutore.nome": false,
        "tutore.email": true,
        "tutore.telefono": true,
        "tutore.cognome": false
    });

    const columns = [
        {field: 'iscritto.cognome', headerName: 'Cognome Iscritto', flex: 1, valueGetter: (params) => capitalizeFirst(params.row.iscritto?.cognome)},
        {field: 'iscritto.nome', headerName: 'Nome Iscritto', flex: 1, valueGetter: (params) => capitalizeFirst(params.row.iscritto?.nome)},
        {field: 'iscrizione.categoria', headerName: 'Categoria', flex: 1,
            valueGetter: (params) => categories[params.row.iscrizione?.categoria]?.sigla||categories[params.row.iscrizione?.categoria]?.label},
        {field: 'iscritto.dataDiNascita', headerName: 'Data di nascita', flex: 1, valueGetter: (params) => params.row.iscritto?.dataDiNascita},
        {field: 'documenti', headerName: 'Documenti Completi', flex: 1,
            valueGetter: (params) => params.row.privacy && params.row.fototessera && params.row.certificato ? 'SI': 'NO'},
        {field: 'iscrizione.tipologia', headerName: 'Tipo Iscrizione', flex: 1,
            valueGetter: (params) => _.find(subscriptionTypes, ['label', params.row.iscrizione?.tipologia]).sigla||params.row.iscrizione?.tipologia},
        {field: 'iscrizione.divisa', headerName: 'Divisa', flex: 1, valueGetter: (params) => params.row.iscrizione?.divisa,
            renderCell: (params) => params.row.iscrizione?.divisa === "true"
                ? <Check sx={{color: 'green'}}/>
                : params.row.iscrizione?.divisa === "false"
                    ? <Close color={'disabled'}/>
                    : '-'
        },
        {field: 'iscrizione.zaino', headerName: 'Zaino', flex: 1, valueGetter: (params) => params.row.iscrizione?.zaino,
            renderCell: (params) => params.row.iscrizione?.zaino === true ? <Check sx={{color: 'green'}}/> : <Close color={'disabled'}/>
        },
        {field: 'tutore.telefono', headerName: 'Telefono Tutore', flex: 1, valueGetter: (params) => params.row.tutore?.telefono},
        {field: 'tutore.email', headerName: 'Email Tutore', flex: 1, valueGetter: (params) => params.row.tutore?.email},

        {field: 'tutore.nome', headerName: 'Nome Tutore', flex: 1, valueGetter: (params) => capitalizeFirst(params.row.tutore?.nome)},
        {field: 'tutore.cognome', headerName: 'Cognome Tutore', flex: 1, valueGetter: (params) => capitalizeFirst(params.row.tutore?.cognome)},
        {field: 'createdAt', headerName: 'Registrazione', flex: 1,
            type: 'dateTime',
            valueGetter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000) : '';
            },
        },
        {field: 'updatedAt', headerName: 'Ultimo aggiornamento', flex: 1,
            type: 'dateTime',
            valueGetter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000) : '';
            },
        },
        {field: 'certificato', headerName: 'Certificato', flex: 1, valueGetter: (params) => params.row.certificato ? 'SI' : 'NO'},
        {field: 'fototessera', headerName: 'Fototessera', flex: 1, valueGetter: (params) => params.row.fototessera ? 'SI' : 'NO'},
        {field: 'privacy', headerName: 'Privacy', flex: 1, valueGetter: (params) => params.row.privacy ? 'SI' : 'NO'},
        {field: 'iscritto.comuneRedisenza', headerName: 'Comune di residenza', flex: 1, valueGetter: (params) => params.row.iscritto?.comuneRedisenza},
        {field: 'iscritto.documento', headerName: 'Documento', flex: 1, valueGetter: (params) => params.row.iscritto?.documento},
        {field: 'iscritto.numeroDocumento', headerName: 'Numero Documento', flex: 1, valueGetter: (params) => params.row.iscritto?.numeroDocumento},
        {field: 'iscritto.scadenzaDocumento', headerName: 'Scadenza Documento', flex: 1, valueGetter: (params) => params.row.iscritto?.scadenzaDocumento},
        {field: 'iscritto.nazionalita', headerName: 'Nazionalità', flex: 1, valueGetter: (params) => params.row.iscritto?.nazionalita},
        {field: 'iscritto.via', headerName: 'Via', flex: 1, valueGetter: (params) => params.row.iscritto?.via},
        {field: 'iscritto.numero', headerName: 'Numero', flex: 1, valueGetter: (params) => params.row.iscritto?.numero},
        {field: 'iscritto.cap', headerName: 'CAP', flex: 1, valueGetter: (params) => params.row.iscritto?.cap},
        {field: 'iscritto.citta', headerName: 'Città', flex: 1, valueGetter: (params) => params.row.iscritto?.citta},
    ];

    useEffect(() => {
        setLoading(true)
        fetchData().then()
    }, [])

    async function fetchData() {
        const response = await UserService.getUsers()
        console.log("response users:", response.data)
        setUsers(response.data)
        setLoading(false)
    }

    const filteredUsers = useMemo(() => {
        if(!users)
            return null
        if(categoryFilter)
            return _.filter(users, ['iscrizione.categoria', categoryFilter])
        else return users
    }, [users, categoryFilter])

    function handleChangeCategoryFilter(event) {
        setCategoryFilter(event.target.value);
    }

    return (
        <main>
            <Box>
                <Container maxWidth={false}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{width:'100%', maxWidth:'180px'}}>
                        </div>
                    </div>
                </Container>
            </Box>
            <Container sx={{paddingTop: '2rem'}} maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1, mt: 3}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Utenti Registrati</Typography>
                    <FormControl sx={{minWidth: 250}}>
                        <InputLabel id="demo-simple-select-label">Filtro Categoria</InputLabel>
                        <Select
                            margin={'dense'}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={categoryFilter}
                            label="Filtro Categoria"
                            onChange={handleChangeCategoryFilter}
                        >
                            <MenuItem value={''}>-</MenuItem>
                            {
                                Object.entries(categories).map(([key, value]) => (
                                    <MenuItem value={key}>{(value.sigla ? (value.sigla + ' - ') : '') + value.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <DataGrid
                            loading={loading||!filteredUsers}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            onRowClick={(params, event, details) => {
                                navigate("/user/" + params.row.id);
                                // TODO: Show row details
                            }}
                            disableSelectionOnClick
                            componentsProps={{ toolbar: { csvOptions } }}
                            components={{ Toolbar: GridToolbar }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={columns}
                            rows={filteredUsers}
                        />
                    </div>
                </div>
            </Container>
        </main>
    )
}

export default Home
