export const API_URL = "https://prod-atletica-cernusco-api-jgmusahvqq-ey.a.run.app/";
//export const API_URL = "http://localhost:8080/";

export const categories = {
    esordienti1: {label: "Esordienti C: 2017 - 2016", sigla: "ESO C"},
    esordienti2: {label: "Esordienti B: 2015 - 2014", sigla: "ESO B"},
    esordienti3: {label: "Esordienti A: 2013 - 2012", sigla: "ESO A"},
    ragazzi: {label: "Ragazzi/e: 2011 - 2010", sigla: null},
    cadetti: {label: "Cadetti/e: 2009 - 2008", sigla: null},
}

export const subscriptionTypes = [
    {label: "Monosettimanale martedì", sigla: "MAR"},
    {label: "Monosettimanale giovedì", sigla: "GIO"},
    {label: "Completo", sigla: null},
    {label: "Bisettimanale", sigla: null},
]